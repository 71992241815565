export default {
    sampleTrxfields: ['sid', 'bannerFoundUrl', 'finalUrl', 'country', 'netType', 'channel', 'hosts', 'urlChain', 'logs', 'banner', 'ad', 'landing'],
    sampleTrxData: [
        {
            'sid': '6409bf64979f0a1e28fccd41', 'bannerFoundUrl': 'bannerurl.com', 'finalUrl': 'finalurl.com',
            'country': "IND",
            'netType': "Jio", 'channel': 1, 'hosts': 'example.com,google.com', 'urlChain': 'test,test,test2', 'logs': 'testlog',
            'banner': '', 'add': '', 'landing': ''
        },
    ],
    sampleSmarturlFields: ['name', 'country', 'url'],
    sampleSmarturlData: [
        { 'name': 'Sample_1', 'country': 'IND', 'url': 'http://google.com' },
        { 'name': 'Sample_2', 'country': 'IND, PAK', 'url': 'http://facebook.com' },
    ]
}