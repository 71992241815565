import api from './api'

export default {
    addSmarturl(data){
    return api.execute(`post`,`/moderator/smarturl`, data)
    },
    getSmarturls(){
        return api.execute(`get`,`/moderator/smarturl`)
    },
    deleteSmartUrls(data){
        return api.execute(`delete`,`/moderator/smarturl`, data)
    },
    editSmarturl(data){
        return api.execute(`put`,`/moderator/smarturl`,data)
    },
    csvUpload(data){
        return api.execute(`post`,`/moderator/smarturl/csv`,data)
    },
}
